import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Paper from "@material-ui/core/Paper"


const ContactsPage = () => {

  return (
    <Layout>
      <SEO title="Home" />
      <Paper elevation={3}>
        Contacts
      </Paper>
    </Layout>
  )
}
export default ContactsPage
